












































import Vue from 'vue'
import { TOKEN } from './index.vue'
import { ExchangeWalletPortfolio, ExchangeWalletPortfolioRes } from '@/services/exchange/models'

type TableRecord = ExchangeWalletPortfolio & {
  total: number
}

export default Vue.extend({
  name: 'TokensTable',

  props: {
    tokenFilter: {
      type: String as () => TOKEN,
      required: true
    },

    dataExchanges: {
      type: Array as () => ExchangeWalletPortfolioRes[],
      required: true
    },

    loading: {
      type: Boolean,
      default: false
    },

    searchTokenKey: {
      type: String,
      default: ''
    }
  },

  computed: {
    tableData(): TableRecord[] {
      if (this.dataExchanges.length === 0) return []
      const tokenExistData = this.dataExchanges.filter((item) => item.metadata.currency === this.tokenFilter)[0]

      const tableData: TableRecord[] = []

      for (const key in tokenExistData) {
        if (key !== 'metadata') {
          tableData.push({
            ccy: tokenExistData[key].ccy,
            share: tokenExistData[key].share * 100 || 0,
            change_24h: tokenExistData[key].change_24h || 0,
            price: tokenExistData[key].price || 0,
            balance_ccy: tokenExistData[key].balance_ccy || 0,
            total: tokenExistData[key][`balance_${this.tokenFilter.toLowerCase()}`] || 0
          })
        }
      }

      tableData.sort((a, b) => (a.total > b.total ? -1 : 1))

      const filterKey = this.searchTokenKey.trim().toLowerCase()

      return filterKey ? tableData.filter((item) => item.ccy.trim().toLowerCase().includes(filterKey)) : tableData
    },

    totalPrice(): number {
      if (this.dataExchanges.length === 0) return 0
      const tokenExistData = this.dataExchanges.filter((item) => item.metadata.currency === this.tokenFilter)[0]

      if (this.tokenFilter === 'USD') {
        return Number(tokenExistData.metadata?.total.balance_usd)
      } else {
        return Number(tokenExistData.metadata?.total.balance_eur)
      }
    },

    fiatSymbol(): string {
      return this.tokenFilter === 'USD' ? '$' : '€'
    }
  }
})
